import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useParams, useNavigate } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Container,
  CircularProgress,
} from "@mui/material";
import InvoicePage from "./invoicePage";
import useStyles from "./styles";
import CampaignDetailBanner from "./campaignDetailBanner";
import { toast } from "react-toastify";

import InfluencerListing from "./influencerListing";
import InfluencerTasks from "./influencerTasks";
import { useMutation, useQuery } from "@apollo/client";
import START_CAMPAIGN from "../../graphql/mutations/startCampaign";
import IS_CAMPAIGN_INVOICED from "../../graphql/queries/isCampaignInvoiced";
import START_GIFTED_CAMPAIGN from "../../graphql/mutations/startGiftedCampaign";
import INITIALIZE_CHAT_ROOMS from "../../graphql/mutations/initializeChatRooms";
import UPDATE_BRAND_CONTRACT from "../../graphql/mutations/updateBrandContract";
import S3 from "aws-s3";
import s3_config from "../../config/s3_config";
import ReactQuill from "react-quill";
import { gql } from "@apollo/client";

const CampaignsApproved = () => {
  const classes = useStyles();
  const nav = useNavigate();
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const [taskMade, setTaskMade] = useState(false);
  const [invoiceAccepted, setInvoiceAccepted] = useState(false);
  const [campaignType, setCampaignType] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [influencerCount, setInfluencerCount] = useState(0);
  const [defaultContract, setDefaultContract] = useState();
  const [campaignContract, setCampaignContract] = useState();
  const [editContract, showEditContract] = useState(false);

  useQuery(IS_CAMPAIGN_INVOICED, {
    variables: {
      campaignId: id,
    },
    onCompleted: (res) => {
      setInvoiceAccepted(res.isCampaignInvoiced);
    },
  });
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  };

  const [start] = useMutation(START_CAMPAIGN, {
    onCompleted: () => {
      nav(`/campaignsDetail/${id}`);
    },
  });
  const [startGifted] = useMutation(START_GIFTED_CAMPAIGN, {
    onCompleted: () => {
      nav(`/campaignsDetail/${id}`);
    },
  });

  const [enableChats] = useMutation(INITIALIZE_CHAT_ROOMS, {
    variables: { campaignId: id },
  });

  const handleCampaignStart = () => {
    if (campaignType === "Paid" && invoiceAccepted && taskMade) {
      start({
        variables: {
          userId: localStorage.getItem("currentUser"),
          campaignId: id,
        },
        onCompleted: (resp) => {
          resp && enableChats();
          nav(`/campaignsDetail/${id}`);
        },
      });
    } else if (campaignType === "Gifted" && taskMade) {
      startGifted({
        variables: {
          userId: localStorage.getItem("currentUser"),
          campaignId: id,
        },
        onCompleted: (resp) => {
          resp && enableChats();
          nav(`/campaignsDetail/${id}`);
        },
      });
    }
  };

  const loadContract = async () => {
    await fetch(
      "https://authentic-pps.s3.eu-west-2.amazonaws.com/authentic_default_contract.txt"
    ).then((r) => {
      r.text().then((d) => {
        setDefaultContract(d);
      });
    });
  };

  const handleEditContract = () => {
    showEditContract(true);
  };

  const loadCampaignContract = async () => {
    await fetch(campaign?.contract).then((r) => {
      r.text().then((d) => {
        setCampaignContract(d);
      });
    });
  };

  useEffect(() => {
    loadContract();
  }, []);

  useEffect(() => {
    loadCampaignContract();
  }, [campaign]);

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.mainGrid}
      justifyContent="center"
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        sx={{ marginBottom: 2 }}
      >
        <Grid
          item
          xs={10}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="details-switch"
            sx={{ maxWidth: 280 }}
          >
            <Tab label="Campaign Details" {...a11yProps(0)} />
            <Tab
              label="Invoice"
              {...a11yProps(1)}
              disabled={campaignType === "Gifted"}
            />
          </Tabs>
        </Grid>
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          {error && (
            <Typography
              variant="caption"
              color="error"
              align="center"
              sx={{ width: "100%" }}
            >
              Create Tasks {campaignType === "Paid" && "& Accept Invoice"}
            </Typography>
          )}

          <Button
            variant="contained"
            sx={{
              marginRight: "20px",
              backgroundColor: "#FFA3C7",
              "&:hover": { backgroundColor: "#FFA3C7" },
            }}
            size="small"
            onClick={() => {
              handleEditContract();
            }}
          >
            Edit Contract
          </Button>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FFA3C7",
              "&:hover": { backgroundColor: "#FFA3C7" },
            }}
            size="small"
            onClick={() => {
              if (campaignType === "Paid") {
                (!invoiceAccepted && setError(true)) ||
                  (!taskMade && setError(true));
              } else {
                !taskMade && setError(true);
              }
              handleCampaignStart();
            }}
          >
            Start Campaign
          </Button>
        </Grid>
      </Grid>
      <TabPanel value={value} index={0}>
        <CampaignDetailBanner
          campaignId={id}
          influencerCount={influencerCount}
          setCampaignType={setCampaignType}
          setCampaign={setCampaign}
        />
        <InfluencerListing
          campaignId={id}
          setInfluencerCount={setInfluencerCount}
          campaignType={campaignType}
          campaign={campaign}
        />
        <InfluencerTasks
          campaignId={id}
          setTaskMade={setTaskMade}
          invoiceAccepted={invoiceAccepted}
          campaignStarted={false}
        />

        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={
            { __html: defaultContract } || { __html: "Loading..." }
          }
        />
        {campaignContract && (
          <div style={{ width: "100%" }}>
            <b>
              <br />
              Additional Clauses by brand
            </b>
            <div
              dangerouslySetInnerHTML={
                { __html: campaignContract } || { __html: "Loading..." }
              }
            />
          </div>
        )}
      </TabPanel>

      {campaignType === "Paid" && (
        <TabPanel value={value} index={1}>
          <InvoicePage
            campaignId={id}
            invoiceAccepted={invoiceAccepted}
            setInvoiceAccepted={setInvoiceAccepted}
            agencyCommission={campaign.user?.agencyCommission}
          />
        </TabPanel>
      )}

      <EditContractDialog
        editContract={editContract}
        handleClose={showEditContract}
        campaignContract={campaignContract}
        setCampaignContract={setCampaignContract}
        campaign={campaign}
      />
    </Grid>
  );
};

const EditContractDialog = ({
  editContract,
  handleClose,
  campaignContract,
  setCampaignContract,
  campaign,
  ...props
}) => {
  const [extendedContract, setExtendedContract] = useState(campaignContract);
  const [updateBrandContract] = useMutation(UPDATE_BRAND_CONTRACT, {
    onCompleted: () => {
      toast.success("Contract Updated Successfully");
    },
  });

  useEffect(() => {
    setExtendedContract(campaignContract);
  }, [campaignContract]);

  const send = () => {
    setCampaignContract(extendedContract);
    const blob = new Blob([extendedContract], { type: "text/plain" });
    const S3Client = new S3(s3_config);

    S3Client.uploadFile(blob)
      .then((data) => {
        updateBrandContract({
          variables: {
            campaignId: campaign.id,
            contract: data.location,
          },
        });
      })
      .catch((err) => {});
    handleClose(false);
  };

  return (
    <Dialog
      open={editContract}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Edit Contract</DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ height: 500, width: 650 }}>
          <ReactQuill
            theme="snow"
            value={extendedContract}
            onChange={setExtendedContract}
            style={{ height: 400 }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={send} color="primary">
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CampaignsApproved;
